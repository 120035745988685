import React, { useState } from 'react';

interface ChatInputProps {
    value?: string;
    onChange?: (value: string) => void;
    onSubmit: (message: string) => void;
    placeholder?: string;
    buttonText?: string;
    className?: string;
}

export const ChatInput = ({ 
    value, 
    onChange, 
    onSubmit, 
    placeholder = "Custom category or product...", 
    buttonText = "Send",
    className = ""
}: ChatInputProps) => {
    const [localValue, setLocalValue] = useState(value || '');

    const handleChange = (newValue: string) => {
        setLocalValue(newValue);
        onChange?.(newValue);
    };

    return (
        <div className={`flex rounded-full border border-gray-300 overflow-hidden mt-4 w-fit ${className}`}>
            <input
                type="text"
                value={value ?? localValue}
                onChange={(e) => handleChange(e.target.value)}
                placeholder={placeholder}
                className="px-4 py-2 text-sm bg-transparent focus:outline-none w-[300px]"
            />
            <button
                type="submit"
                onClick={() => onSubmit(localValue.trim())}
                className="px-4 py-2 text-sm bg-blue-500 text-white hover:bg-blue-600 transition-colors"
            >
                {buttonText}
            </button>
        </div>
    );
}; 