import React from 'react';
import { SavedAnswer } from '../../types/chat';

interface QuestionBlockProps {
    question: string;
    questionIndex: number;
    possibleAnswers: string[];
    answers: SavedAnswer[];
    setAnswers: React.Dispatch<React.SetStateAction<SavedAnswer[]>>;
}

export const QuestionBlock = ({ question, questionIndex, possibleAnswers, answers, setAnswers }: QuestionBlockProps) => {
    const handleAnswerChange = (answer: string) => {
        setAnswers(prevAnswers => {
            const exists = prevAnswers.some(a => a.questionIndex === questionIndex && a.answer === answer);
            if (exists) {
                return prevAnswers.filter(a => !(a.questionIndex === questionIndex && a.answer === answer));
            } else {
                return [...prevAnswers, { questionIndex, answer, question }];
            }
        });
    };

    return (
        <div className="space-y-2">
            <p className="font-medium text-[15px]">{question}</p>
            <div className="flex flex-wrap items-center gap-2">
                {possibleAnswers.map((answer, i) => (
                    <button
                        key={i}
                        onClick={() => handleAnswerChange(answer)}
                        className={`px-4 py-2 rounded-full text-sm transition-colors
                            ${answers.some(a => a.questionIndex === questionIndex && a.answer === answer)
                                ? 'bg-blue-500 text-white'
                                : 'border border-gray-300 text-gray-700 hover:bg-blue-400'
                            }`}
                    >
                        {answer}
                    </button>
                ))}
                <div className="rounded-full border border-gray-300 w-44">
                    <input
                        type="text"
                        className="px-4 py-2 text-sm bg-transparent focus:outline-none  w-44"
                        placeholder="Other answer..."
                        onChange={(e) => {
                            const value = e.target.value;
                            setAnswers(prev => {
                                const filtered = prev.filter(a => !(a.questionIndex === questionIndex && a.answer.startsWith('Other:')));
                                if (value) {
                                    return [...filtered, { questionIndex, answer: `Other: ${value}`, question }];
                                }
                                return filtered;
                            });
                        }}
                        value={answers.find(a => a.questionIndex === questionIndex && a.answer.startsWith('Other:'))?.answer.replace('Other: ', '') || ''}
                    />
                </div>
            </div>
        </div>
    );
}; 